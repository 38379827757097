<template>
  <div class="content">
    <div class="card-from">
      <div class="title-brand">
        <img src="/img/logo.png" alt="logo.png" />
      </div>
      <div class="image text-center">
        <img src="/img/error/error502.svg" alt="" />
        <div class="title-error">Bad Gateway</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.title-brand {
  position: absolute;
  color: #fff;
  font-size: 54px;
  font-weight: bold;
  top: 2%;
  left: 5%;
}

.title-error {
  font-weight: 500;
  font-size: 30px;
  margin-top: 30px;
  color: rgba(0, 0, 0, 1);
}

.sub-title-error {
  color: rgba(137, 137, 137, 1);
  font-size: 15px;
}

.content {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image img {
  width: 300px;
}
</style>
